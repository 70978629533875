var render = function () {
  var _vm$content;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.$validate(_vm.getIframe);
      }
    }
  }, [!((_vm$content = _vm.content) !== null && _vm$content !== void 0 && _vm$content.disabled) ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('vx-input', {
    staticClass: "mr-2 w-100",
    attrs: {
      "type": "url",
      "placeholder": "Please enter url",
      "name": "URL",
      "unique": {
        items: _vm.contents,
        key: 'url',
        index: 0
      },
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.$validate(_vm.getIframe);
      }
    },
    model: {
      value: _vm.url,
      callback: function callback($$v) {
        _vm.url = $$v;
      },
      expression: "url"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('vx-btn', {
    attrs: {
      "outlined": "",
      "loading": _vm.loading.getIframe,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.getIframe);
      }
    }
  }, [_vm._v(" Retrieve ")])], 1)], 1) : _vm._e(), _vm.hasError ? _c('div', {
    staticClass: "error--text"
  }, [_vm._v(" We are not able to retrieve that URL. Are you sure it's a public link? "), _c('br'), _vm._v(" If you're having issues let us know at "), _c('vx-link', {
    attrs: {
      "support": ""
    }
  })], 1) : _vm._e(), _vm.loading.stillLoading ? _c('div', {
    staticClass: "info--text"
  }, [_vm._v(" We're still working to get your content. Give us just a bit longer. We'll let you know if there's an issue ")]) : _vm._e(), _vm.content.url ? _c('ContentInfo', {
    attrs: {
      "content": _vm.content,
      "bookings": _vm.bookings,
      "loading": _vm.loading,
      "options": _vm.options.contentOptions
    },
    on: {
      "upload": function upload($event) {
        return _vm.openModal(_vm.content);
      }
    }
  }) : _vm._e(), _c('FileUploader', {
    ref: "uploader",
    attrs: {
      "options": _vm.fileUploaderOptions
    },
    on: {
      "resolve": _vm.onResolvFileUploader
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }