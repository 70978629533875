<template>
  <v-col cols="12">
    <v-form ref="form" @submit.prevent="$validate(getIframe)">
      <v-row v-if="!content?.disabled">
        <v-col cols="10">
          <vx-input
            v-model="url"
            class="mr-2 w-100"
            type="url"
            placeholder="Please enter url"
            name="URL"
            :unique="{
              items: contents,
              key: 'url',
              index: 0,
            }"
            required
            @change="$validate(getIframe)"
          />
        </v-col>
        <v-col cols="2">
          <vx-btn outlined :loading="loading.getIframe" color="primary" @click="$validate(getIframe)">
            Retrieve
          </vx-btn>
        </v-col>
      </v-row>
      <div v-if="hasError" class="error--text">
        We are not able to retrieve that URL. Are you sure it's a public link? <br />
        If you're having issues let us know at <vx-link support />
      </div>

      <div v-if="loading.stillLoading" class="info--text">
        We're still working to get your content. Give us just a bit longer. We'll let you know if there's an issue
      </div>
      <ContentInfo
        v-if="content.url"
        :content="content"
        :bookings="bookings"
        :loading="loading"
        :options="options.contentOptions"
        @upload="openModal(content)"
      />
      <FileUploader ref="uploader" :options="fileUploaderOptions" @resolve="onResolvFileUploader" />
    </v-form>
  </v-col>
</template>
<script>
import { IframelyService } from '@tutti/services';
export default {
  name: 'ContentUrlField',
  components: {
    ContentInfo: () => import('@admin/views/venue/edit/components/ContentInfo.vue'),
    FileUploader: () => import('@tutti/components/FileUploader.vue'),
  },
  props: {
    content: { type: Object, required: true },
  },
  data() {
    return {
      url: '',
      hasError: false,
      loading: {
        updateContent: false,
        getIframe: false,
        stillLoading: false,
      },
      bookings: [],
      contents: [],
      options: {
        contentOptions: {},
      },
      fileUploaderOptions: {
        maxNumberOfFiles: 1,
        params: {
          from: 'content',
        },
        headers: { authorization: this.$cookies.get(process.env.VUE_APP_AUTH_TOKEN_KEY), partners: false, admin: true },
      },
    };
  },
  methods: {
    async getIframe() {
      this.loading.getIframe = true;
      this.hasError = false;

      const timeout = setTimeout(() => {
        this.loading.stillLoading = true;
      }, 5000);

      let timestampExist = this.url.match('timestamp');
      if (timestampExist == null) this.url = this.url + '?timestamp=' + Date.now();

      const response = await IframelyService.get({ url: this.url });
      if (response) {
        this.content.set(response.data);
      } else {
        this.hasError = true;
      }

      clearTimeout(timeout);
      this.loading.stillLoading = false;
      this.loading.getIframe = false;
      this.$emit('setIframelyData', this.content);
    },
    openModal(content) {
      this.selectedContent = content;
      this.$refs?.uploader?.openModal();
    },
    onResolvFileUploader(files) {
      if (files && files[0] && this.selectedContent) {
        this.selectedContent.thumbnail = files[0].url;
      }
    },
  },
};
</script>
